<template>
    <nav v-if="meta.last_page > 1" class="border-t border-gray-200 dark:border-gray-700 px-4 flex items-center justify-between sm:px-0">
        <div class="-mt-px w-0 flex-1 flex">
            <template v-if="meta.links[0].url">
                <div @click="redirectToPage(meta.links[0])"
                     class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 cursor-pointer">
                    Previous
                </div>
            </template>
        </div>

        <div class="hidden md:-mt-px md:flex">
            <div v-for="(page, index) in getLinks()" :key="index" @click="redirectToPage(page)"
                 class="border-transparent text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium cursor-pointer"
                 :class="index + 1 == meta.current_page ? 'border-main4 text-main4' : ''">
                {{ page.label }}
            </div>
        </div>

        <div class="-mt-px w-0 flex-1 flex justify-end">
            <template v-if="meta.links[meta.links.length - 1].url">
                <div @click="redirectToPage(meta.links[meta.links.length - 1])"
                     class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 cursor-pointer">
                    Next
                </div>
            </template>
        </div>
    </nav>
</template>

<script>
export default {
    name: "Pagination",
    props: {
        meta: {
            type: Object,
            required: true
        },
        onPageChange: {
            required: true
        }
    },
    methods: {
        getLinks() {
            const links = [...this.meta.links]
            links.splice(0, 1)
            links.splice(this.meta.links.length - 2, 1)

            return links
        },
        redirectToPage(page) {
            let pageNumber = "";
            if (page.url) {
                pageNumber = page.url.replace(`${this.meta.path}?page=`, "");
            }

            this.onPageChange(pageNumber);

            this.$router.replace({
                query: Object.assign({}, this.$route.query, {page: pageNumber})
            })
        }

    },
}
</script>

<style>

</style>