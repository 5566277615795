<template>
  <Content :filter="true" :showFilterDefault="true">
    <template v-slot:topbar>
      <div class="flex h-full items-center">
        <router-link to="/stock/procurements/create" class="rounded-full w-12 h-12 bg-gray-200 dark:bg-main4 flex justify-center items-center leading-none hover:bg-gray-300 transition duration-300 ease-in-out dark:text-gray-100">
          <PlusIcon class="w-7 h-7" />
        </router-link>
      </div>
    </template>

    <template v-slot:filter>

      <div class="flex h-full items-center">
        <Radio radioName="selectedStatus" v-model="filter.selectedStatus" :items="statuses" @change="getItems" />
      </div>

      <div class="flex flex-wrap items-center">
        <div class="flex flex-wrap sm:flex-nowrap items-center mr-3  ">
          <div class="w-48">
            <Input type="date" v-model="filter.from" @change="getItems"/>
          </div>
          <span class="p-1">-</span>
          <div class="w-48">
            <Input type="date" v-model="filter.to" @change="getItems"/>
          </div>
        </div>

        <button type="button"
                @click="firstDayOfYear"
                class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
          No gada sākuma
        </button>

        <button type="button"
                @click="firstDayOfMonth"
                class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
          No mēneša sākuma
        </button>
        <button type="button"
                @click="firstDayOfWeek"
                class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
          No nedēļas sākuma
        </button>
        <button type="button"
                @click="today"
                class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
          Šodien
        </button>

        <button type="button"
                @click="getReportPdf"
                class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
          Drukāt PDF
        </button>

        <button type="button"
                @click="getReportExcel"
                class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
          Drukāt Excel
        </button>

      </div>

    </template>

    <template v-slot:content>
        <template v-if="procurements && procurements.data.length > 0">
          <div class="sm:rounded-md mb-3">
            <ul role="list" class="">
              <template v-for="item in procurements.data" :key="item.id">
                <ProcurementListItem :item="item" />
              </template>
            </ul>
          </div>

          <Pagination :meta="procurements.meta" :onPageChange="onPageChange" />
        </template>

        <template v-else>
            <p class="py-6 text-center dark:text-gray-300">
                Diemžēl nekas netika atrasts
            </p>
        </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import ProcurementListItem from "@/components/Stock/Procurement/ProcurementListItem"
import Pagination from "@/components/Components/Pagination";
import Radio from "@/components/Components/Radio"
import Input from "@/components/Components/Input"
import {
  PlusIcon,
} from "@heroicons/vue/solid";
import Dates from "@/services/Helpers/dates";

export default {
  name: "Procurements",
  components: {
    Pagination,
    ProcurementListItem,
    Radio,
    PlusIcon,
    Input
  },
  data: () => ({
    showFiltersTab: true,
    isLoading: false,
    selectedFilter: 'all',
    selectedMainBranch: null,
    filters: [
      {name: 'all', display_name: 'visi'},
    ],
    mainCustomerBranches: [
      {id: 1, name: 'Rīga'},
      {id: 2, name: 'Kuldīga'},
      {id: 3, name: 'Valmiera'},
    ],
    statuses: [
      {
        name: 'Akceptēts',
        value: 1
      },
      {
        name: 'Sagatave',
        value: 0
      },
    ],
    filter: {
      selectedStatus: null,
      from: Dates.firstDayOfMonth(),
      to: new Date().toISOString().substr(0, 10),
    },
    queryParams: null,
  }),
  created() {
    this.$store.dispatch("setDataLoading", true)

        // Get query params from URL
        this.queryParams = this.$route.query

        // If there are no URL params set default params
        if(Object.keys(this.queryParams).length === 0) {
            this.$router.replace({
                query: {
                    warehouse_id: '',
                    is_accepted: '',
                    internal_procurements: 0,
                    page: Search.getCurrentPage(),
                    q: this.search,
                    from: this.filter.from,
                    to: this.filter.to,
                }
            })
        }

        // Update component data from query prams
        this.setFiltersFromParams()

        this.searchProcurements()
  },
  mounted() {
    this.$store.dispatch('resetSearch')
  },
  computed: {
    ...mapGetters({
        procurements: 'procurements',
        mainCustomer: 'systemMainCustomer',
        search: 'search',
        // isLoading: 'loading',
    })
  },
  watch: {
    search: function () {
      this.searchProcurements()
    },
    selectedFilter: function () {
      this.isLoading = true
    }
  },
  methods: {
    getItems() {
        this.$router.replace({
            query: {
                is_accepted: this.filter.selectedStatus || this.filter.selectedStatus === 0 ? this.filter.selectedStatus : '',
                page: 1,
                q: this.queryParams.q,
                from: this.filter.from,
                to: this.filter.to,
            }
        })

        this.queryParams = this.$route.query
        this.searchProcurements()
    },
    setFiltersFromParams(){
        this.filter.selectedStatus = this.queryParams.is_accepted ? this.queryParams.is_accepted : '';
        this.filter.from = this.queryParams.from ? this.queryParams.from : this.filter.from;
        this.filter.to = this.queryParams.to ? this.queryParams.to : this.filter.to;
    },
    searchProcurements: debounce(function () {
      this.$store.dispatch('filterProcurements', this.queryParams)
        .then(response => {
            this.isLoading = false
        })
    }, 500),
    onPageChange(page) {
      this.searchProcurements()
    },
    addProcurement() {
      this.$router.push({ name: 'procurements.create'})
    },

    viewProcuerment(procurementId) {
      this.$router.push({path: '/stock/procurements/' + procurementId})
    },

    changeStatus(item) {
      if (confirm("Vai esi drošs, ka vēlies mainīt statusu?")) {
        this.$Progress.start();
        this.$store.dispatch("updateProcurement", {
          id: item.id,
          is_accepted: true,
        }).then( response => {
          this.$store.dispatch('getProcurements', {
            page: this.procurements.meta.current_page,
          })
        });
      }
    },
    firstDayOfYear() {
      this.filter.from = Dates.firstDayOfYear()
      this.getItems()
    },
    firstDayOfMonth() {
      this.filter.from = Dates.firstDayOfMonth()
      this.getItems()
    },
    firstDayOfWeek() {
      this.filter.from = Dates.firstDayOfWeek()
      this.getItems()
    },
    today() {
      this.filter.from = Dates.today()
      this.getItems()
    },
    getReportPdf() {
      this.$store.dispatch('getProcurementsReportPdf', {
        from: this.filter.from,
        to: this.filter.to,
        is_accepted: this.queryParams.is_accepted
      })
    },
    getReportExcel() {
      this.$store.dispatch('getProcurementsReportExcel', {
        from: this.filter.from,
        to: this.filter.to,
        is_accepted: this.queryParams.is_accepted
      })
    },
  }
}
</script>

<style>

</style>