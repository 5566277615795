<template>
  <item-card :url="`/stock/procurements/${item.id}`" contentClass="grid grid-cols-12">
    <template v-slot:title>
      <Badge :text="item.is_accepted ? 'Akceptēts' : 'Sagatave'" class="mr-2 mb-2 md:mb-0" :class="item.is_accepted ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'" />
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        {{item.supplier.name}}
      </h3>
    </template>

    <template v-slot:buttons>

      <template v-if="!item.is_accepted && userHavePermission('manage_procurement')">
        <Button icon="delete" @click="deleteProcurement(item.id)" />
      </template>
    </template>

    <template v-slot:content>

      <div class="col-span-6 lg:col-span-4 xl:col-span-2">
        <item-text title="Datums" :text="item.procurement_date" />
      </div>
      
      <div class="col-span-6 lg:col-span-4 xl:col-span-2">
        <item-text title="Piegādātāja PPR nr." :text="item.supplier_uuid" />
      </div>
      
      <div class="col-span-6 lg:col-span-4 xl:col-span-2">
        <item-text title="Kopā EUR" :text="item.total" />
      </div>
      
      <div class="col-span-6 lg:col-span-4 xl:col-span-2">
        <item-text title="PVN EUR" :text="item.vat" />
      </div>
      
      <div class="col-span-6 lg:col-span-4 xl:col-span-2">
        <item-text title="Summa EUR" :text="item.total_with_vat" />
      </div>

      <div class="col-span-6 lg:col-span-4 xl:col-span-2">
        <Badge v-if="item.payment_date" :text="'Apmaksāts ' + item.payment_date"
               :className="`bg-green-600 text-white  mr-2 mb-2 mt-5`"
        />
      </div>

    </template>
  </item-card>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Badge from "@/components/Components/Badge"

export default {
  name: "ProcurementListItem",
  components: {
    ItemCard,
    ItemText,
    Badge,
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    userHavePermission(permission) {
      return this.$userCan(permission)
    },
    deleteProcurement(itemId) {
      if (confirm('Vai esi drošs?')) {
        this.$Progress.start()
        this.$store.dispatch('deleteProcurement', itemId)
      }
    },
  }
}
</script>

<style>

</style>