<template>
  <div>
    <label v-show="label" class="block text-sm font-medium text-gray-700 dark:text-gray-300 text-left mb-1">
      {{label}}
    </label>
    <div class="flex flex-wrap relative">
      <div v-for="item in items" :key="item.value" class="flex items-center mr-4">
        <input type="radio" :id="`${item.value}-${item[itemName]}`" :name="`${radioName}-${name}`" @change="change" :value="item.value" v-model="selected" class="mr-2 cursor-pointer">
        <label :for="`${item.value}-${item[itemName]}`" class="cursor-pointer dark:text-gray-300">{{item[itemName]}}</label>
      </div>
      <p v-if="errors && errors[0]" class="text-xs text-red-500 absolute top-full -mt-0.5 left-0 pl-3 pt-1" id="input2-error">{{errors[0]}}</p>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  data() {
    return {
      selected: null,
      name: null
    }
  },
  props: {
    modelValue: {
      // type: String,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: null
    },
    itemName: {
      type: String,
      default: "name"
    },
    radioName: {
      type: String,
      default: "name"
    },
    errors: {
      type: Array,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    change() {
      this.$emit('update:modelValue', this.selected)
    }
  },
  mounted() {
    if (this.modelValue !== null || this.modelValue !== 'undefined'|| this.modelValue === 0) {
      const selected = this.items.filter(item => item.value == this.modelValue)
      if (selected.length > 0) {
        this.selected = selected[0].value
      }
    }
    this.name = uuidv4()
  }
}
</script>