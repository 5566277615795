<template>
  <div class="mr-10 mb-1 py-3 md:py-2 lg:py-1">
    <p v-if="title" class="flex items-center text-sm text-gray-500 dark:text-gray-400" :class="titleClass">
      {{title}}
    </p>
    <p v-if="text || text === 0" class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0" :class="textClass">
      {{text}}
    </p>
    <slot name="content" />
  </div>
</template>

<script>
export default {
  name: "ItemText",
  props: {
    title: {
      type: String,
      default: null
    },
    text: {
      type: [String, Number],
      default: null
    },
      textClass: {
          type: String,
          default: null
      },
      titleClass: {
          type: String,
          default: null
      },
  }
}
</script>

<style>
</style>