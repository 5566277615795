<template>
  <li class="rounded-lg w-full bg-white dark:bg-gray-750" :class="`mb-${mb}`">
    <div class="block rounded-lg border-l-4 border-transparent" :class="[className, url ? 'cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-770' : '']" ref="card" @click="redirect">
      <div class="px-4 py-4 sm:px-6">
        <div class="flex justify-between items-start" :class="content ? 'mb-4' : ''">
          <div class="md:flex-shrink-0 flex items-center flex-wrap gap-3 text-gray-900 dark:text-gray-300 dark:text-gray-300 flex-1" ref="buttons">
            <slot name="title" />
          </div>
          <div class="md:flex-shrink-0 flex items-center flex-wrap justify-end" ref="buttons">
            <slot name="buttons" />
          </div>
        </div>

        <template v-if="content">
          <div class="mt-2" :class="contentClass">
            <slot name="content" />
          </div>
        </template>
      </div>
    </div>

    <div :class="additionalClass">
      <slot name="additional"/>
    </div>
  </li>
</template>

<script>
export default {
  name: "ItemCard",
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: null
    },
    className: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    clickFn: {
      default: null
    },
    blank: {
      type: Boolean,
      default: false
    },
    mb: {
      type: String,
      default: "3"
    },
    content: {
      type: Boolean,
      default: true
    },
    contentClass: {
      type: String,
      default: "sm:flex flex-wrap gap-y-2"
    },
    additionalClass: {
      type: String,
      default: "px-6 sm:px-6"
    },
  },
  methods: {
    redirect(event) {
      const buttonsContainer = this.$refs.buttons

      if (buttonsContainer && event.target !== buttonsContainer && !buttonsContainer.contains(event.target) && event.target.nodeName !== 'BUTTON' && event.target.nodeName !== 'path' && event.target.nodeName !== 'svg' && this.url) {
        if (this.blank) {
          let routeData = this.$router.resolve(
          {
              path: this.url
          });
          window.open(routeData.href, '_blank');
        } else {
          this.$router.push({path: this.url});
        }
      } else if(this.clickFn) {
        this.clickFn();
      } else {
        return;
      }
    }
  }
}
</script>

<style>

</style>